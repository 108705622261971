<template>
  <div style="overflow: hidden;">
    <div>
      <NavBar
      style="max-height: 50px;margin-bottom: 10px;"
      :username="currentUserName"
      :pageName="pageName"
      ></NavBar>
    </div>
    <v-app>
      <div align="center">

        <!-- Edit Promotion Popup dialog starts -->
        <div>
            <v-row justify="center">
              <v-dialog v-model="editPromotionPopupFlag" persistent max-width="700">
                <v-card>
                  <v-card-title class="editPromotionDialogTitle">
                    <div>
                      <p style="font-weight:bold;margin-top:10px" >
                        Edit Promotion Details
                        </p>
                    </div>
                    <div>
                      <v-btn  icon @click="editPromotionPopupFlag=false">
                      <img src="@/assets/popupClose.svg" >
                      </v-btn>
                    </div>
                  </v-card-title>
                  <v-card-text>
                    <div>
                        <div>
                            <div class="titleAndTextFiledClass">
                                <div>
                                    <p style="text-align:left">Promotion Name</p>
                                </div>
                                <div>
                                    <v-text-field
                                    v-model="editPromotionName"
                                    placeholder=editPromotionName
                                    solo
                                    >
                                    </v-text-field>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div>
                            <div class="titleAndTextFiledClass">
                                <div>
                                    <p style="text-align:left">Description<span style="color:red">*</span></p>
                                </div>
                                <div>
                                    <v-textarea
                                    v-model="editDescription"
                                    placeholder=editDescription
                                    solo
                                    width="100%"
                                    :rules="descriptionRules"
                                    >
                                    </v-textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                  </v-card-text>
                  <v-card-actions style="display:flex; justify-content:flex-end">
                    <div >
                      <v-btn
                      color="primary"
                      style="background-color: #1467BF;"
                      width="100px"
                      @click="editPromotionMaster()"
                      :disabled="isButtonClicked || editPromotionName.trim().length == 0 || editPromotionName.trim().length >= 160 || editDescription.trim().length == 0 || editDescription.trim().length >= 160"
                      >
                      Save
                      </v-btn>
                    </div>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-row>
        </div>
        <!-- Edit Promotion Popup dialog ends -->

        <!-- date validation dialog starts -->
        <div>
          <v-row justify="center">
            <v-dialog v-model="dateValidationModal" persistent max-width="300">
              <v-card>
                <v-card-title style="word-break: normal; padding: 5%">
                  Start Date cannot be greater than End Date
                </v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                  color="primary"
                  style="background-color: #1467BF;"
                  @click="dateValidationModal = false"
                  >
                    Ok
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-row>
        </div>
        <!-- date validation dialog ends -->
        <!-- Discount validation dialog starts -->
        <div>
          <v-row justify="center">
            <v-dialog v-model="discountErrorModal" persistent max-width="300">
              <v-card>
                <v-card-title style="word-break: normal; padding: 5%">
                  Discount amount cannot be greater than or equal to Minimun spent amount
                </v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                  color="primary"
                  style="background-color: #1467BF;"
                  @click="discountErrorModal = false"
                  >
                    Ok
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-row>
        </div>

        <div>
          <v-row justify="center">
            <v-dialog v-model="free_appointment_warning" persistent max-width="300">
              <v-card>
                <v-card-title style="word-break: normal; padding: 5%">
                  Discount amount is higher than minimum spend amount. Might result in free appointment. <br> Ok to proceed. Cancel to change.
                </v-card-title>
                <div style="display: flex; justify-content: space-around;">
                  <div>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                      color="primary"
                      style="background-color: #1467BF;"
                      @click="closeFreeWarning('cancel')"
                      >
                        Cancel
                      </v-btn>
                    </v-card-actions>
                  </div>
                  <div>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                      color="warning"
                      style="background-color: #EB5757;"
                      @click="closeFreeWarning('ok')"
                      >
                        Ok
                      </v-btn>
                    </v-card-actions>
                  </div>
                </div>
              </v-card>
            </v-dialog>
          </v-row>
        </div>
        <!-- Discount validation dialog ends -->
        <v-dialog v-model="promo_model" max-width="60%" persistent style="overflow: hidden;">
          <v-card>
            <div class="TitleHeader">
              <div>
                <v-card-title class="TitleText">
                  Create Promotion Code
                </v-card-title>
              </div>
            </div>
            <div align="left" style="overflow: hidden;">
              <table class="tableClass">
                <tr>
                  <td style="padding: 16px;">Promotion Name</td>
                  <td style="padding: 16px;"><v-text-field hide-details="auto" v-model="promotion_family_name" label="Promotion Name"></v-text-field></td>
                </tr>
                <tr>
                  <td style="padding: 16px;">Promotion Display code</td>
                  <td style="padding: 16px;"><v-text-field hide-details="auto" v-model="promotion_display_code" label="Promotion Display Code"></v-text-field></td>
                </tr>
                <tr>
                  <td style="padding: 16px;">Promotion Description</td>
                  <td style="padding: 16px;"><v-text-field hide-details="auto" v-model="promotion_family_description" label="Promotion Description"></v-text-field></td>
                </tr>
                <tr>
                  <td style="padding: 16px;">Promotion Type</td>
                  <td style="padding: 16px; vertical-align:middle;"><v-select label="Promotion Type" hide-details="auto" v-model="selected_promotion_type" :items="promotion_types_list" outlined></v-select></td>
                </tr>
                <tr>
                  <td style="padding: 16px;">Discount Value</td>
                  <td style="padding: 16px;"><v-text-field hide-details="auto" v-model="promotion_family_discount_value" label="Discount" type="number" @wheel="$event.target.blur()" class="hideArrows" :prefix = "selected_promotion_type !='PERCENTAGE_OFF' ? 'MMK' : ''" :suffix="selected_promotion_type =='PERCENTAGE_OFF' ? '%' : ''"></v-text-field></td>
                </tr>
                <tr v-if="selected_promotion_type == 'PERCENTAGE_OFF'">
                  <td style="padding: 16px;">Max Discount Amount ( 9999999 if no upper limit )</td>
                  <td style="padding: 16px;"><v-text-field hide-details="auto" v-model="promotion_family_max_discount_amount" label="Max discount" type="number" @wheel="$event.target.blur()" class="hideArrows" prefix="MMK"></v-text-field></td>
                </tr>
                <tr>
                  <td style="padding: 16px;">Minimum Spend amount ( 0 if not applicable )</td>
                  <td style="padding: 16px;"><v-text-field hide-details="auto" v-model="promotion_family_minimum_spend_amount" label="Min spend" type="number" @wheel="$event.target.blur()" class="hideArrows" prefix="MMK"></v-text-field></td>
                </tr>
                <tr>
                  <td style="padding: 16px;">Discount Scope</td>
                  <td style="padding: 16px;"><v-select label="Discount target category" hide-details="auto" v-model="selected_target_group" :items="all_target_list" outlined @change="selected_list_values = []"></v-select></td>
                </tr>
                <tr v-if="selected_target_group == 'Specialization'">
                  <td style="padding: 16px;">List</td>
                  <td style="padding: 16px;">
                    <v-select label="Select Applicable On" hide-details="auto" v-model="selected_list_values" multiple :items="all_spec_list" item-text="specialization_name" item-value="_id" outlined>
                      <template v-slot:selection="{ item, index }">
                        <v-chip v-if="index === 0">
                          <span>{{ item.specialization_name }}</span>
                        </v-chip>
                        <span
                          v-if="index === 1"
                          class="grey--text text-caption"
                          >
                          (+{{ selected_list_values.length - 1 }} others)
                        </span>
                      </template>
                    </v-select></td>
                </tr>
                <tr v-if="selected_target_group == 'Doctor'">
                  <td style="padding: 16px;">List</td>
                  <td style="padding: 16px;">
                    <v-select label="Select Applicable On" hide-details="auto" v-model="selected_list_values" multiple :items="all_doc_list" item-text="doctor_name" item-value="_id" outlined>
                      <template v-slot:selection="{ item, index }">
                        <v-chip v-if="index === 0">
                          <span>{{ item.doctor_name }}</span>
                        </v-chip>
                        <span
                          v-if="index === 1"
                          class="grey--text text-caption"
                          >
                          (+{{ selected_list_values.length - 1 }} others)
                        </span>
                      </template>
                    </v-select></td>
                </tr>
                <tr>
                  <td style="padding: 16px;">Valid From</td>
                  <td style="padding: 16px;">
                    <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto" >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="valid_from" label="Dates List" background-color="#F4F6FA" outlined v-bind="attrs" v-on="on" style="border-radius:8px;" ></v-text-field>
                      </template>
                      <v-date-picker style="padding:20px; height: 500px;color: black" v-model="valid_from" show-adjacent-months :allowed-dates="allowedDates"></v-date-picker>
                    </v-menu>
                  </td>
                </tr>
                <tr>
                  <td style="padding: 16px;">Valid Till</td>
                  <td style="padding: 16px;">
                    <v-menu ref="menu2" v-model="menu2" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto" >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="valid_till" label="Dates List" background-color="#F4F6FA" outlined v-bind="attrs" v-on="on" style="border-radius:8px;" ></v-text-field>
                      </template>
                      <v-date-picker @change="dateValidator" style="padding:20px; height: 500px;color: black" v-model="valid_till" show-adjacent-months :allowed-dates="allowedDates"></v-date-picker>
                    </v-menu></td>
                </tr>
                <tr>
                  <td style="padding: 16px;">Total Redemptions</td>
                  <td style="padding: 16px;"><v-text-field hide-details="auto" v-model="promotion_quantity" label="Number of Redemptions" type="number" @wheel="$event.target.blur()" class="hideArrows"></v-text-field></td>
                </tr>
                <tr>
                  <td style="padding: 16px;">Total Redemptions Per Customer</td>
                  <td style="padding: 16px;"><v-text-field hide-details="auto" v-model="promotion_quantity_per_person" label="Number of Redemptions Per Customer" type="number" @wheel="$event.target.blur()" class="hideArrows"></v-text-field></td>
                </tr>
              </table>
            </div>
            <div style="display: flex;justify-content: flex-end;">
              <div style="padding: 16px">
                <v-btn class="primary"
                @click="createpromotions"
                style="background-color: #1467BF;"
                :disabled="checkDisabled()"
                >Create</v-btn>
              </div>
              <div style="padding: 16px">
                <v-btn class="error" @click="closePopup" style="background-color: #EB5757;">Close</v-btn>
              </div>
            </div>
          </v-card>
        </v-dialog>
      </div>
      <v-row style="height: 96vh; background-color: #f5faff;">
        <v-col cols="2">
          <SideBar :pageName="pageName"></SideBar>
        </v-col>
        <v-col cols="9">
          <div style="margin:10px 20px 20px 50px">
          <div v-if="!isLoading" align="left" style="display:flex;flex-direction:row;justify-content:space-between">
            <!-- <div class="TitleFilterclass"> -->
              <div style="width: 30%" align="left" >
                <span class="TitleClass"><h3>Promo code Management</h3></span>
              </div>
              <div class="FilterClass">
                <!-- <div>
                </div> -->
                <div >
                  <span style="font-size: 26pt; justify-content: space-between;align-items: end;">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }" style="display: flex; justify-content: end; align-items: center;">
                        <v-btn :disabled="isAddDisabled" append rounded color="primary" v-on="on" style="border-radius: 8px; height: 35px;margin-top: 2vh; background-color: #1467BF;" @click="openCreatePopup">
                          <span style="font-size: 18pt;"> + </span>
                          <span style="font-weight: 600;margin-top: 2%;">Create Promo</span>
                          <span v-if="isAddDisabled">
                            <v-progress-circular
                              indeterminate
                              color="primary"
                            ></v-progress-circular>
                          </span>
                        </v-btn>
                      </template>
                      <span>Create Promo</span>
                    </v-tooltip>
                  </span>
                </div>
              </div>
            <!-- </div> -->
          </div>
          <div align="center" style="padding-left: 64px; padding-top: 48px;" v-if="isLoading">
            <div>
              <v-progress-circular
                  indeterminate
                  color="primary"
              ></v-progress-circular>
            </div>
          </div>
          <div v-if = "!isLoading" style="padding-top: 42px;">
            <div class="fixTableHead">
              <table id="promotion_master_table" class="tableClass" v-if="allPromos.length > 0">
                <tr align="center">
                  <th>Created Date</th>
                  <th>Promo Name</th>
                  <th>Display Code</th>
                  <th>Description</th>
                  <th>Total Used</th>
                  <th>Total Issued</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                  <th>Availability Status</th>
                  <th>Actions</th>
                </tr>
                <tr align="center" v-for="promo in allPromos" :key="promo._id">
                  <td>{{ promo.createdAt.split("T")[0] }}</td>
                  <td>{{ promo.promotion_name }}</td>
                  <td>{{ promo.display_code }}</td>
                  <td>{{ promo.description }}</td>
                  <td>{{ promo.promotionInfo }}</td>
                  <td>{{ promo.total_redemption_limit }}</td>
                  <td>{{ promo.valid_from }}</td>
                  <td>{{ promo.valid_till }}</td>
                  <td>
                    <v-switch
                    :loading = "isEditLoading"
                    :disabled = "isDisabled"
                    v-model="promo.promotionStatus"
                    @change="changeStatusOfPromotion(promo)"
                    ></v-switch>
                  </td>
                  <td>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <a style="width: 100%; margin:5px;" icon @click="viewParticularPromoFamily(promo._id)" depressed v-on="on">
                          <img src="https://s3iconimages.mymedicine.com.mm/walkin_view.svg" style="height: 24px; width: 24px;"/>
                        </a>
                        <a style="width: 100%; margin:5px;" icon @click="editPromotionPopUp(promo)" depressed v-on="on">
                          <img src="https://s3iconimages.mymedicine.com.mm/edit.svg" style="height: 24px; width: 24px;"/>
                        </a>
                      </template>
                      <span>View</span>
                    </v-tooltip>
                  </td>
                </tr>
              </table>
              <div align="center" v-else>
                No Promotions Generated
              </div>
            </div>
          </div>
       
       
       </div></v-col>
      </v-row>
    </v-app>
  </div>
</template>


<script>
import axios from "axios";
import NavBar from "../../../views/navbar.vue";
import SideBar from "../../../views/sideBar.vue";

export default {
  name: "ViewAllPromos",
  components: {
    NavBar,SideBar,
  },
  data() {
    return {
      pageName: "AllPromos",
      currentUser: null,
      currentUserName: null,
      currentCustomer: null,
      currentUserId: null,
      isLoading: false,
      isAddDisabled: false,
      allPromos: false,
      dateValidationModal: false,
      discountErrorModal: false,
      promo_model: false,
      promotion_family_name: null,
      promotion_display_code: null,
      promotion_family_description: null,
      promotion_types_list: ['PERCENTAGE_OFF', 'FLAT_OFF'],
      selected_promotion_type: null,
      promotion_family_discount_value: null,
      promotion_family_minimum_spend_amount: null,
      all_target_list: ['Specialization','Doctor'],
      selected_target_group: null,
      selected_list_values: null,
      all_spec_list: null,
      all_doc_list: null,
      valid_from: null,
      valid_till: null,
      promotion_quantity: null,
      promotion_quantity_per_person: null,
      isButtonClicked: false,
      menu: null,
      menu2: null,
      promotion_family_max_discount_amount: null,
      free_appointment_warning: false,
      warning_accepted: false,
      editPromotionPopupFlag: false,
      promotionId: '',
      editPromotionName: '',
      editDescription: '',
      descriptionRules:
      [ 
        v =>  (v.trim().length < 160) || 'Description must be 160 characters or less.',
        v => v.trim() != '' || "Description can not be empty"
      ],
      isEditLoading: false,
      isDisabled: false,
    }
  },
  mounted(){
    this.currentUser = this.$cookies.get("token");
    if (!this.currentUser) {
      this.$router.push({
        name: "AdminLogin",
      });
    } else {
      this.isLoading = true;
      var get_admin_body = {
        token : this.currentUser,
        typeOfUser : "ADMIN",
        adminType: "ADMIN"
      };
      axios.post(process.env.VUE_APP_BACKEND_URL + "/getAdmin",get_admin_body)
      .then((adminReadSuccess) =>{
        this.currentUserName = adminReadSuccess.data.data.admin_name;
        this.currentUserId = adminReadSuccess.data.data._id;
        var promo_body = {
          token: this.currentUser,
          typeOfUser: "ADMIN"
        };
        axios.post(process.env.VUE_APP_BACKEND_URL + "/readAllPromotionFamilies", promo_body)
        .then((readAllPromoSuccess) =>{
          // console.log(readAllPromoSuccess.data.data);
          this.allPromos = readAllPromoSuccess.data.data;
          this.allPromos.forEach((promo) => {
            promo.promotionStatus = promo.status == 'ACTIVE' ? true : false;
          });
          this.isLoading = false;
        })
        .catch((readAllPromoError) =>{
          console.log(readAllPromoError);
          this.isLoading = false;
        });
      })
      .catch((adminReadError) => {
        console.log("[ERROR] Error while reading admin data: ", adminReadError);
        if (adminReadError.response.status == 401) {
          window.alert("Sorry !! You are unauthorized !!");
          this.$router.push({
            name: "AdminLogin",
          });
        } else {
          window.alert("Something went wrong...");
        }
      });
    }
  },
  methods: {
    editPromotionPopUp(promo) {
      this.promotionId = promo._id;
      this.editPromotionName = promo.promotion_name;
      this.editDescription = promo.description;
      this.editPromotionPopupFlag = true;
    },
    editPromotionMaster(){
      this.isButtonClicked = true;
      
      let editPromotionMasterBody = {
        token: this.currentUser,
        typeOfUser: 'ADMIN',
        promotionId: this.promotionId,
        promotionName: this.editPromotionName,
        promotionDescription: this.editDescription
      };

      axios.post(process.env.VUE_APP_BACKEND_URL + '/editPromotionMaster', editPromotionMasterBody)
      .then(() => {
        this.editPromotionPopupFlag = false;
        window.alert('Promotion edited successfully');
        this.$router.go();
      })
      .catch((editPromotionMasterError) => {
        if (editPromotionMasterError.response.status == 401) {
          window.alert('Sorry, You are unauthorized! Please login again.');
        } else {
          window.alert('Something went wrong...');
        }
        this.editPromotionPopupFlag = false;
        this.$router.go();
      });
    },
    changeStatusOfPromotion(item) {
      this.isEditLoading = true;
      this.isDisabled = true;
      let changePromotionStatusBody = {
        token : this.currentUser,
        typeOfUser : "ADMIN",
        promotionId: item._id,
        status: item.promotionStatus == true ? "ACTIVE" : "INACTIVE"
      };

      axios.post(process.env.VUE_APP_BACKEND_URL + "/changePromotionStatus", changePromotionStatusBody)
      .then((changePromotionStatusResponse) => {
        this.isEditLoading = false;
        this.isDisabled = false;
      })
      .catch((changePromotionStatusError) => {
        this.isEditLoading = false;
        this.isDisabled = false;
        if (changePromotionStatusError.response.status == 401) {
          window.alert('Sorry, You are unauthorized! Please login again.');
        } else {
          window.alert('Something went wrong...');
        }
        this.$router.go();
      });
    },
    closeFreeWarning(buttonType)
    {
      this.free_appointment_warning = false;
      if(buttonType == 'ok')
      {
        this.warning_accepted = true;
        this.createpromotions();
      }
      else
      {
        this.isButtonClicked = false;
      }
    },
    checkDisabled()
    {
      // console.log('Valid Till', this.promotion_family_name)
      return (!(this.promotion_family_name && this.promotion_display_code  && this.promotion_family_description  && this.selected_promotion_type  && this.selected_target_group  && this.valid_from && this.valid_till && parseInt(this.promotion_quantity) > 0 && parseInt(this.promotion_quantity_per_person) > 0 && parseInt(this.promotion_quantity_per_person) < parseInt(this.promotion_quantity) && this.selected_list_values    && parseInt(this.promotion_family_discount_value) > 0 && ((this.selected_promotion_type == 'FLAT_OFF' && parseInt(this.promotion_family_minimum_spend_amount) >= 0) || (this.selected_promotion_type == 'PERCENTAGE_OFF' && parseInt(this.promotion_family_discount_value) < 100 && parseInt(this.promotion_family_max_discount_amount) >= 0))) || this.isButtonClicked);
    },
    createpromotions()
    {
      this.isButtonClicked = true;
      var create_promo_body = {
        token: this.currentUser,
        typeOfUser: "ADMIN",
        promotionName: this.promotion_family_name.trim(),
        displayCode: this.promotion_display_code.trim(),
        promotionType: this.selected_promotion_type,
        discount: parseInt(this.promotion_family_discount_value),
        description: this.promotion_family_description.trim(),
        maxDiscount: this.selected_promotion_type == "FLAT_OFF"? parseInt(this.promotion_family_discount_value) : parseInt(this.promotion_family_max_discount_amount) > 0 ? parseInt(this.promotion_family_max_discount_amount) : 9999999,
        minSpend: parseInt(this.promotion_family_minimum_spend_amount),
        doctorFeeSplit: 0,
        commissionFeeSplit: 100,
        validFrom: this.valid_from,
        validTill: this.valid_till,
        applicableOn: [this.selected_target_group],
        applicableList: this.selected_list_values,
        totalRedemptionLimit: parseInt(this.promotion_quantity),
        redemptionLimitPerUser: parseInt(this.promotion_quantity_per_person),
        generatedBy: this.currentUserId,
      }
      if(this.selected_promotion_type == 'PERCENTAGE_OFF' || (this.selected_promotion_type == 'FLAT_OFF' && (parseInt(this.promotion_family_minimum_spend_amount) > parseInt(this.promotion_family_discount_value)) || this.warning_accepted))
      {
        axios.post(process.env.VUE_APP_BACKEND_URL + "/createPromotionMaster", create_promo_body)
        .then((createSuccess) =>{
          window.alert("Promotion created!");
          this.$router.go();
        })
        .catch((createError) =>{
          console.log(createError);
          if(createError.response.status == 305)
            window.alert("Promotion with same Display Code is already active");
          else
            window.alert("Error in creating Promotion");
          this.isButtonClicked = false;
        });
      }
      else
      {
        this.free_appointment_warning = true;
      }
    },
    discountValidation(){
      if(this.promotion_family_discount_value && parseInt(this.promotion_family_discount_value) >= parseInt(this.promotion_family_minimum_spend_amount) && this.selected_promotion_type == 'FLAT_OFF'){
        this.discountErrorModal = true
      }
    },
    allowedDates(val)
    {
      return val >= new Date().toISOString().split("T")[0];
    },
    dateValidator(){
      if(this.valid_from > this.valid_till)
      {
        this.dateValidationModal = true
        this.valid_till = ''
      }
    },
    closePopup()
    {
      this.promo_model = false;
      this.isAddDisabled = false; 
      this.isButtonClicked = false;
    },
    openCreatePopup()
    {
      this.promo_model = true;
      this.isAddDisabled = true;
      var all_request = {
        token : this.currentUser,
        typeOfUser : "ADMIN"
      }
      axios.post(process.env.VUE_APP_BACKEND_URL + "/readAllSpecializations", all_request)
      .then((all_spec_response) =>{
        this.all_spec_list = all_spec_response.data.data;
      })
      .catch((all_spec_error) =>{
        console.log(all_spec_error)
      });
      axios.post(process.env.VUE_APP_BACKEND_URL + "/getAllAvailableDoctors", all_request)
      .then((all_doc_response) => {
        this.all_doc_list = all_doc_response.data.data;
      })
      .catch((all_doc_error) =>{
        console.log(all_doc_error);
      });
    },
    viewParticularPromoFamily(promotion_id)
    {
      this.$router.push({
        path: '/admin/viewPromo/' + promotion_id
      });
    }
  }
}
</script>
<style>

  .hideArrows input::-webkit-outer-spin-button,
  .hideArrows input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    margin: 0 !important;
  }
  /* Firefox */
  .hideArrows input[type=number] {
    -moz-appearance: textfield !important;
  }
</style>
<style scoped>
::-webkit-scrollbar {
  width: 8px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: lightgrey !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888 !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #888 !important;
}

.editPromotionDialogTitle{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.TitleFilterclass
{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  vertical-align: middle;
   padding-bottom: 20px;
}
.TitleClass
{
  font-weight: 800;
  font-size: 12pt;
  /* line-height: 29px; */
  text-transform: uppercase;
  letter-spacing: 1px;
  padding-left: 5px;
}
.FilterClass
{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
  .fixTableHead {
  overflow-y: auto;
  height: 50vh;
}
.fixTableHead thead th {
  position: sticky;
  top: 0;
}
table {
  border-collapse: collapse;        
  width: 100%;
}
td,
th {
  border: 0px;
}
td {
  padding: 2px;
  font-size: 8pt;
  font-weight: 700;
  padding-left: 5px;
  text-align: left;
  /* width: 80px; */
}
th {
  background-color: #1467bf;
  color: white;
  border: 0px;
  font-size: 8pt;
  font-weight: 500;
  margin-left: 10%;
  position: sticky;
  top: 0;
  z-index: 5;
  height: 40px;
  padding-left: 5px;
  /* padding-right: 10px; */ 
  text-align: left;

  /* width: 80px; */
}
tr {
  border-bottom: #d4eeff 1px solid !important;
}
.tableClass {
  border-spacing: 0px;
  width: 100%;
  border-collapse: collapse;
  z-index: 0;
  background-color: white;
  overflow-y: scroll;
}
table > tr > td:last-child {
    white-space: nowrap;
}


</style>
